import React from "react"
import PropTypes from "prop-types"
import { PageRenderer, graphql } from "gatsby"
import Fade from 'react-reveal/Fade'
import he from 'he'
import Img from "gatsby-image"

import Link from "../utils/link"

import Header from "../components/header"
import SEO from '../components/seo'

import "./post.scss"

function Template(props) {
  const post = props && props.data.wpPost

  const building = typeof window === "undefined"
  const [setNewsPageData] = React.useState(!building && window.newsPageData)

  React.useEffect(() => {
    window.setNewsPageData = () => {
      if ( window.newsPageData && typeof setNewsPageData !== "undefined" ) setNewsPageData(window.newsPageData)
    }

    if (document) {
      document.body.style.maxHeight = "100vh"
      document.body.style.overflow = "hidden"
    }
    return () => {
      document.body.style.maxHeight = "unset"
      document.body.style.overflow = "auto"
    }
  }, [])

  return (
    <>
      <PageRenderer key={"/resources"} location={{ pathname: "/resources" }} />
      <SEO title={he.decode(post.seo.title)} bodyClass='' description={post.seo.metaDesc} />
      <div className="overlay-page">
        <Header closeAndNavigateTo="/resources" />
        <div className="content">
          <div className="container">
            <Fade 
              bottom 
              distance='40px'
            >
              <div className="post">
                <h1 className="post__title">{post.title}</h1>
                <div className="post__image">
                  <Img
                    fluid={
                      post.featuredImage.node.localFile.childImageSharp.fluid
                    }
                    alt={post.title}
                  />
                </div>
                <div
                  className="post__details"
                  dangerouslySetInnerHTML={{ __html: post.content }}
                />
              </div>
            </Fade>
          </div>
        </div>
      </div>
    </>
  )
}

Template.propTypes = {
  data: PropTypes.object,
}

export const serviceQuery = graphql`
  query($id: String!) {
    wpPost(id: { eq: $id }) {
      title
      content
      slug
      seo {
        title
        metaDesc
        opengraphTitle
        opengraphDescription
        opengraphImage {
          sourceUrl
          altText
          localFile {
            childImageSharp {
              original {
                src
                width
                height
              }
            }
          }
        }
      }
      date(formatString: "DD MMMM YYYY")
      featuredImage {
        node {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1200, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      acf {
        author
      }
    }
  }
`

export default Template
